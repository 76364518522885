import { Injectable, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BrandingService } from 'tt-branding-lib';

@Injectable({
  providedIn: 'root',
})
export class MetricsService implements OnInit {
  config: any;
  constructor(
    private store: AngularFirestore,
    private branding: BrandingService
  ) {
    this.branding.config$.subscribe((data) => (this.config = data));
  }
  ngOnInit(): void {}
  getMetricsData(year: number) {
    return this.store
      .collection('/metrics/' + this.config?.app?.id + '/' + year, (ref) =>
        ref.where('month', '==', new Date().getMonth())
      )
      .valueChanges();
  }
}
