import { Injectable } from '@angular/core';
import { switchMap, catchError, take } from 'rxjs/operators';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { FirebaseAuthService } from 'tt-authentication-lib';
import { User } from 'tt-authentication-lib/lib/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: FirebaseAuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.currentuser$.pipe(
      take(1),
      switchMap((user: User) => {
        if (user?.uid) {
          if (user.unregistered) {
            this.router.navigateByUrl('/unregistered');
            return of(false);
          }
          if (user.role?.includes('admin')) {
            return of(true);
          } else {
            this.router.navigateByUrl('/unauthorized');
            return of(false);
          }
        } else {
          this.router.navigateByUrl('/login');
          return of(false);
        }
      }),
      catchError(() => {
        this.router.navigateByUrl('/login');
        return of(false);
      })
    );
  }
}
